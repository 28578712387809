import { Controller } from 'stimulus'
import $ from 'jquery'
import { ajax } from '@rails/ujs'

export default class extends Controller {
  static targets = ['ppcfMakerId', 'ppcfCarModelId', 'ppcfModelYearCode', 'ppcfMileageCode',
                    'ppcfScheduledSaleTimeId', 'ppcfCarModelIdLabel', 'formOverlay']

  loadCarModel() {
    let makerId    = this.ppcfMakerIdTarget.value.split('-')[0]
    const carModel = $(this.ppcfCarModelIdTarget)

    this.ppcfCarModelIdLabelTarget.innerText = '必須'
    this.ppcfCarModelIdLabelTarget.classList.remove('completed')

    if (makerId !== '') {
      carModel.prop('disabled', false)
      $.ajax({
        url: '/ajax_get_car_model_by_maker_id',
        data: { maker_id: makerId },
        success: function(response) {
          carModel.html(response)
        }
      })
    } else {
      carModel.prop('disabled', true)
      carModel.val('')
    }
  }

  setCompleted(event) {
    let target = $(event.target)
    let label  = target.closest('.form-group')[0].querySelector('.c__label__require')
    let error  = target.closest('.form-group')[0].querySelector('.invalid-feedback')

    if ((target.attr('type') == 'radio' && !target.is(':checked')) || target.val() == '') {
      label.classList.remove('completed')
      label.innerText = '必須'
    } else {
      label.classList.add('completed')
      label.innerText = 'OK'
      error.style.display = 'none'
    }
  }

  changeActive(event) {
    this.ppcfScheduledSaleTimeIdTargets.forEach(element => {
      $(element).closest('div.form__radio').removeClass('form__radio__active')
      $(element).prop('checked', false);
    });

    $(event.target).closest('div.form__radio').addClass('form__radio__active')
    $(event.target).prop('checked', true);
  }

  get getDataForm() {
    let ppcfScheduledSaleTimeId = this.ppcfScheduledSaleTimeIdTargets.filter(sl => sl.checked == true)[0]
    let makerId       = this.ppcfMakerIdTarget.value.split('-')
    let carModelId    = this.ppcfCarModelIdTarget.value.split('-')
    let modelYearCode = this.ppcfModelYearCodeTarget.value
    let mileageCode   = this.ppcfMileageCodeTarget.value

    return {
      purchasePriceForm: {
        maker_id:        makerId[0],
        car_model_id:    carModelId[0],
        model_year_code: modelYearCode,
        mileage_code:    mileageCode,
        scheduled_sale_time_id: ppcfScheduledSaleTimeId ? ppcfScheduledSaleTimeId.value : ''
      },
      inquiryForm: {
        maker_code:      makerId[1],
        car_model_code:  carModelId[1],
        model_year_code: modelYearCode,
        mileage_code:    mileageCode
      }
    }
  }

  getMakerPrice() {
    const self = this;
    const resultContainer = document.getElementById('quick_search_result__container')
    let data = this.getDataForm.purchasePriceForm

    ajax({
      url: '/ajax_purchase_price_car_handler',
      type: 'GET',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      beforeSend: function(){
        self.formOverlayTarget.classList.remove('d-none')
        return true;
      },
      success: function (response) {
        resultContainer.innerHTML = response.body.outerHTML
        const headerResult = document.getElementById('ppcf_header_result');
        let modelYearText = self.ppcfModelYearCodeTarget.options[self.ppcfModelYearCodeTarget.selectedIndex].text.substring(0, 5);

        headerResult.innerText = `${modelYearText}式の` +
        `${self.ppcfMakerIdTarget.options[self.ppcfMakerIdTarget.selectedIndex].text}` +
        `${self.ppcfCarModelIdTarget.options[self.ppcfCarModelIdTarget.selectedIndex].text}` +
        `の買取相場価格は…`
      },
      error: function(errors) {
        resultContainer.innerHTML = ''
        Object.entries(errors).forEach(([key, value]) => {
          let el = document.querySelector(`div[data-ppcform-field=${key}]`)

          el.style.display = 'block'
          el.innerText = value
        });
      },
      complete: function(){
        setTimeout(() => self.formOverlayTarget.classList.add('d-none'), 350);
      }
    })
  }

  get homeController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('.content__home__form'), 'home')
  }

  applyDataFormInquiry() {
    const controller = this.homeController
    let data = this.getDataForm.inquiryForm

    const makerEl = document.getElementById('ModalMaker').querySelector(`ul li[value='${data.maker_code}']`)
    controller.modalChangeMaker(makerEl, true, data.car_model_code)

    const modelYearEl = document.getElementById('ModalYear').querySelector(`ul li[value='${data.model_year_code}']`)
    controller.modalChangeYear(modelYearEl, true)

    const mileageEl = document.getElementById('ModalMileage').querySelector(`ul li[value='${data.mileage_code}']`)
    controller.modalChangeMileage(mileageEl, true)
  }
}
