import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    "modalPreEntryInfo",
    "submit",
    "maker",
    "carModel",
    "carInspectionYear",
    "carInspectionMonth",
    "buttonSubmit",
    "purchaseTimeInspected"
  ]

  static values = {
    carModelUrl: String
  }

  initialize() {
    this.updatePurchaseTimeInspected()
    this.availableImageExtention = ['png', 'jpg', 'jpeg', 'pdf']
    this.maximumFileUploadSize = 2 // Mb
  }

  confirmFormPreEntry(event) {
    let checkBoxes = $(event.target);
    this.buttonSubmitTarget.disabled = !checkBoxes.is(':checked');
  }

  closeModalPreEntryInfo(event) {
    event.preventDefault()
    $(this.modalPreEntryInfoTarget).hide()
  }

  changeMaker() {
    let self = this
    let makerId = self.makerTarget.value
    let carModel = $(self.carModelTarget)
    let ajaxCarModelUrl = this.carModelUrlValue

    $.ajax({
      url: ajaxCarModelUrl,
      data: {
        maker: makerId,
        template: 'dropdown_normal'
      },
      success: function(response) {
        carModel.html('')
        carModel.append(response)
        if (makerId === '') {
          carModel.attr("disabled", true)
        } else {
          carModel.attr("disabled", false)
        }
      }
    })
  }

  updatePurchaseTimeInspected(e) {
    if($('#cashback_entry_purchase_time_inspected').length == 1) {
      let year = this.carInspectionYearTarget.value
      let month = this.carInspectionMonthTarget.value
      this.purchaseTimeInspectedTarget.value = `${year}${month}`
      if(e && e.type === 'change') {
        this.removeErrorMessages(e)
      }
    }
  }

  removeErrorMessages(e) {
    let groupError = e.target.closest(".form-group")
    if(groupError.classList.contains('form-group-errors')) {
      groupError.classList.remove('form-group-errors')
      groupError.getElementsByClassName("error_message_field")[0].classList.add("d-none")
    }
  }

  clickInputField(e) {
    let $selectedElement = $(e.target)
    let $uploader = $selectedElement.parents('.uploader')
    let $inputFileField = $uploader.find('input')
    $inputFileField.click()
  }

  async uploadImage(e) {
    let files = e.target.files;
    let $inputFileField = $(e.target)
    let $uploader = $inputFileField.parents('.uploader')
    this._previewImage($uploader, files, $inputFileField);
  }

  removeImagePreview(e) {
    let $uploader = $(e.target).parents('.uploader')
    $uploader.find('input[type=file]').val('')
    $uploader.find('.preview').addClass('d-none')
    $uploader.find('.upload-content').removeClass('d-none');
    let $formGroup = $(e.target).parents('.form-group')
    $formGroup.removeClass('form-group-errors')
    $formGroup.find('.error_message_field').addClass('d-none')
  }

  dragover(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  drop(e) {
    e.stopPropagation();
    e.preventDefault();
    let $inputFileField = $(e.target).closest('.drop-zone').find('input')

    let files = e.dataTransfer.files;
    let $uploader = $(e.target).closest('.uploader')
    this._previewImage($uploader, files, $inputFileField);
  }

  _previewImage($uploader, files, inputFileField) {
    let $imgPreview = $uploader.find('img')
    let $pdfPreview = $uploader.find('iframe')
    let $preview = $uploader.find('.preview')
    let file = files[0]
    let fileExtension = file.name.split('.').pop().toLowerCase();
    let fileSize = file.size / (1024 * 1024.0);

    let errorMessage = ''
    if(fileSize > this.maximumFileUploadSize) {
      errorMessage = `最大ファイルサイズは${this.maximumFileUploadSize}MBまでになります。ファイルサイズを確認してください。`
    } else if (!this.availableImageExtention.includes(fileExtension)) {
      errorMessage = 'jpg、jpeg、png、pdfのいずれかの形式でアップロードしてください。'
    }

    if(errorMessage) {
      let $formGroup = $uploader.parents('.form-group')
      let $errorMessage = $formGroup.find('.error_message_field')
      $uploader.find('.js--btn-remove-image').click()
      $errorMessage.html(errorMessage)
      $errorMessage.removeClass('d-none')
      return false
    }

    inputFileField.prop('files', files)
    if (file.type === 'application/pdf') {
      $preview.find('.js--preview-image').addClass('d-none')
      $preview.find('.js--preview-pdf').removeClass('d-none')
      $pdfPreview.attr('src', URL.createObjectURL(file));
      $imgPreview.attr('src', '');
    } else {
      $preview.find('.js--preview-pdf').addClass('d-none')
      $preview.find('.js--preview-image').removeClass('d-none')
      $imgPreview.attr('src', URL.createObjectURL(file));
      $pdfPreview.attr('src', '');
    }

    $uploader.find('.icon-close').removeClass('d-none')
    $imgPreview.parent('.preview').removeClass('d-none');
    $uploader.find('.upload-content').addClass('d-none');

    let $formGroup = $uploader.parents('.form-group')
    $formGroup.removeClass('form-group-errors')
    $formGroup.find('.error_message_field').addClass('d-none')
  }
}
