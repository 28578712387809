// app/javascript/controllers/index.js

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import ScrollTo from "stimulus-scroll-to"

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register("scroll-to", ScrollTo)
