import { Controller } from 'stimulus'
import $ from 'jquery'
import { Suggest } from 'lib/suggest_email'
export default class extends Controller {
  static targets = [
    "maker",
    "carModel",
    "modelYear",
    "mileage",
    "pref",
    "modal",
    "zipcode",
    "address",
    "statusCar",
    "email",
    "firstName",
    "lastName",
    "tel",
    "telHidden",
    "phoneType",
    "starCar",
    "code",
    "resend",
    "duplicateFlag",
    "invalidFeedbackCode",
    "modalDuplicateInquiry",
    "clientsChecked",
    "invalidFeedbackTel",
    "modalUserInfo",
    "invalidFeedbackMaker",
    "invalidFeedbackCarModel",
    "invalidFeedbackYear",
    "invalidFeedbackMileage",
    "invalidFeedbackZipcode",
    "invalidFeedbackZipcodeNotFound",
    "invalidFeedbackEmailBlank",
    "invalidFeedbackEmail",
    "invalidFeedbackFullName"
  ]

  static values = {
    rootUrl: String,
    carModelUrl: String,
    zipcodeUrl: String,
    confirmUrl: String,
    setDataUrl: String,
    inquiriesUrl: String,
    phoneTypeUrl: String,
    doneUrl: String,
    smsUrl: String
  }

  initialize() {
    this.maker = ''
    this.carModel = ''
    this.modelYear = ''
    this.mileage = ''
    this.statusCarName = $("input[name='car_status']:checked").closest('label').text()
    this.statusCarChecked = $("input[name='car_status']:checked").val()
    this.zipcode = ''
    this.zipcodeNotFound = false
    this.address = ''
    this.email = ''
    this.firstName = ''
    this.lastName = ''
    this.tel = ''
    this.clients = ''
    this.phoneType = ''
    if (this.hasZipcodeTarget){
      this.changeZipcode()
    }
  }

  changeActive(event) {
    let formEle = $(event.target)[0].closest('div.form__radio')
    let statusName = $(event.target)[0].closest('label.form__radio__label').innerText
    this.statusCarName = statusName
    this.statusCarChecked = event.target.value
    $(this.starCarTargets).removeClass('form__radio__active')
    $(formEle).addClass('form__radio__active')
  }

  backForm() {
    window.location.href = this.rootUrlValue
  }

  replaceTel(event) {
    let tel = event.currentTarget.value
    event.currentTarget.value = tel.normalize('NFKC').replace(/[ー - − -]/g, '');
  }

  submitCodeWithKeyboard(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.sendInquiries(event)
    }
  }

  confirm() {
    let self = this

    $.ajax({
      method: "POST",
      url: self.setDataUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        inquiries_user_info: {
          email: self.emailTarget.value,
          firstName: self.firstNameTarget.value,
          lastName: self.lastNameTarget.value,
          tel: self.telTarget.value,
          phone_type: self.phoneType
        },
        inquiries_car_info: {
          master_maker_code: self.makerTarget.value,
          master_car_model_code: self.carModelTarget.value,
          master_model_year_code: self.modelYearTarget.value,
          master_mileage_code: self.mileageTarget.value,
          car_status: self.statusCarChecked,
          zipcode: self.zipcodeTarget.value,
          address: self.addressTarget.value
        }
      },
      success: function(response) {
        window.location.href = self.confirmUrlValue
      }
    })
  }

  changeZipcode(showAlert = true) {
    let self = this
    let zipcode = self.zipcodeTarget.value
    let address = $(self.addressTarget)
    if (zipcode === '') {
      address.val('')
      return;
    }

    let regex_zipcode = zipcode.replace(/[^0-9]/g, "")
    self.zipcodeTarget.value = regex_zipcode
    if (regex_zipcode.length === 7) {
      return $.ajax({
        url: self.zipcodeUrlValue,
        data: { zip_code: regex_zipcode },
        success: function(response) {
          if (response === undefined || response.address_name.length === 0) {
            if (showAlert && !self.zipcodeNotFound) {
              alert('郵便番号−入力された郵便番号の住所が存在しません。')
            }
            self.zipcodeNotFound = true
            address.val('')
          } else {
            self.zipcodeNotFound = false
            address.val(response.address_name)
          }
        }
      })
    } else {
      self.zipcodeNotFound = true
      address.val('')
    }
  }

  async sendTelGetSMSCode(event) {
    let self = this
    let invalid = self.invalidFeedbackTelTarget
    let telTarget = self.telTarget
    let telValue = telTarget.value
    invalid.style['display'] = 'none'

    await this.changeZipcode(false)
    let validate = this.validateAll()
    if (validate)
      return false
    else {
      let phone_type = await this.checkPhoneType(telValue)
      phone_type = phone_type["phone_type"]
      self.phoneType = phone_type
      self.tel = telValue
      if (phone_type === 'fixed_line'){
        self.confirm()
      } else if (phone_type === "toll_free") {
        invalid.style['display'] = 'block'
        invalid.textContent = 'フリーダイヤルでのお問い合わせは受け付けておりません。フリーダイヤル以外の電話番号をご入力ください。'
      } else {
        $.ajax({
          method: 'POST',
          url: self.smsUrlValue,
          data: {
            authenticity_token: $('[name="csrf-token"]')[0].content,
            tel_number: telValue
          },
          success: function(response) {
            if (response?.error?.tel_wrong) {
              invalid.style['display'] = 'block'
              invalid.textContent = response.error.message
              telTarget.removeAttribute('readonly')
            } else if(response?.error?.tel_empty) {
              invalid.style['display'] = 'block'
              invalid.textContent = response.error.message
              telTarget.removeAttribute('readonly')
            } else {
              self.confirm()
            }
          }
        })
      }
    }
  }

  setClientChecked() {
    let clientsChecked = []
    this.clientsCheckedTargets.map((check) => {
      if (check.checked) {
        clientsChecked.push(check.value)
      }
    })
    this.clients = clientsChecked
  }

  sendInquiries(event) {
    $(event.target).parents('button').prop('disabled', true)
    event.preventDefault()
    this.setClientChecked()
    let phone_type = this.phoneTypeTarget.value

    if (phone_type === 'mobile') {
      let code = this.codeTarget.value
      if (code.length === 4) {
        this.code = code
        this.phoneType = phone_type
        return this.inquiries($(event.target))
      }
      else {
        $('#Code').focus();
        $(event.target).parents('button').prop('disabled', false);
        return false
      }
    }
    this.inquiries($(event.target))
  }

  inquiries($eventTarget) {
    let self = this
    let modal = self.modalDuplicateInquiryTarget
    let duplicateFlag = self.duplicateFlagTarget
    let tel = self.telHiddenTarget.value || self.tel
    let code = self.code
    let phone_type = self.phoneType

    $.ajax({
      url: self.inquiriesUrlValue,
      method: 'POST',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        inquiry: {
          client_ids: self.clients,
          duplicateFlag: duplicateFlag.value,
          tel: tel,
          code: code,
          phone_type: phone_type,
          assessment_route: 'web'
        }
      },
      success: function(response) {
        $eventTarget.parents('button').prop('disabled', false)
        if (phone_type == 'mobile') {
          let invalidCode = self.invalidFeedbackCodeTarget
          if (response.error.verify_fail) {
            invalidCode.style['display'] = 'block'
            invalidCode.textContent = response.error.message
            self.jumpToTop();
            return false;
          }
        }
        if (response.error.session_clear) {
          return alert(response.error.message)
        } else if (response.error.duplicate) {
          $('#Code').prop('disabled', true);
          modal.style['display'] = 'block'
          $(duplicateFlag).val(true)
          self.jumpToTop();
          return false;
        } else if (response.error.client_empty) {
          return alert(response.error.message)
        }
        else if (response.error.data_incorrect) {
          return alert(response.error.message)
        }

        window.location.href = self.doneUrlValue
      },
      error: function (response) {
        $eventTarget.parents('button').prop('disabled', false)
        for (const [key, value] of Object.entries(response.responseJSON)) {
          alert(`${value.toString()}`);
        }
      }
    })
  }

  jumpToTop(){
    window.location.href = "#home-old-form"
  }

  checkPhoneType(tel_number) {
    return $.ajax({
      method: 'POST',
      url: this.phoneTypeUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        tel_number: tel_number
      },
      success: function(response) {
        if (response['phone_type'] != null) {
          return response['phone_type']
        }
      }
    })
  }

  validateEmail(email) {
    let reMail =  /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return !reMail.test(email)
  }

  telError() {
    let self = this
    let invalid = self.invalidFeedbackTelTarget
    let telTarget = self.telTarget
    let telValue = telTarget.value
    let reTel = /^(?:\+?\d{1,3}\s*-?)?\(?(?:\d{3})?\)?[- ]?\d{3}[- ]?\d{4}$/
    let tel_format = telValue.replace(/\s/g,'')
    let reTelFWidth = /\s/g

    if (!reTel.test(tel_format) || reTelFWidth.test(tel_format)) {
      invalid.style['display'] = 'block'
      invalid.textContent = '正しい携帯電話番号をご入力ください'
      telTarget.removeAttribute('readonly')
      return true
    } else if (tel_format.length < 10 || tel_format.length > 11) {
      invalid.style['display'] = 'block'
      invalid.textContent = '連絡先(TEL)番号は数値10桁もしくは11桁で入力してください。'
      telTarget.removeAttribute('readonly')
      return true
    }
    return false
  }

  validateAll() {
    let maker = this.makerTarget.value
    let carmodel = this.carModelTarget.value
    let modelyear = this.modelYearTarget.value
    let mileage = this.mileageTarget.value
    let zipcode = this.zipcodeTarget.value
    let email = this.emailTarget
    let lastName = this.lastNameTarget
    let firstName = this.firstNameTarget

    let maker_invalid = this.invalidFeedbackMakerTarget
    let carmodel_invalid = this.invalidFeedbackCarModelTarget
    let modelyear_invalid = this.invalidFeedbackYearTarget
    let mileage_invalid = this.invalidFeedbackMileageTarget
    let zipcode_blank = this.invalidFeedbackZipcodeTarget
    let zipcode_not_found = this.invalidFeedbackZipcodeNotFoundTarget
    let email_blank = this.invalidFeedbackEmailBlankTarget
    let email_invalid = this.invalidFeedbackEmailTarget
    let name_invalid = this.invalidFeedbackFullNameTarget

    maker_invalid.style['display'] = carmodel_invalid.style['display'] = modelyear_invalid.style['display'] =
    mileage_invalid.style['display'] = zipcode_blank.style['display'] = zipcode_not_found.style['display'] =
    email_blank.style['display'] = email_invalid.style['display'] = name_invalid.style['display'] = 'none'
    let checkFormatMail

    let showPopup = maker === '' || carmodel === '' || modelyear === '' || mileage === '' || zipcode === ''
                  || email === '' || lastName === '' || firstName === ''
                  || this.zipcodeNotFound || this.showPopupError()

    let telError = this.telError()

    if (maker === '') {
      maker_invalid.style['display'] = 'block'
    }

    if (carmodel === '') {
      carmodel_invalid.style['display'] = 'block'
    }

    if (modelyear === '') {
      modelyear_invalid.style['display'] = 'block'
    }

    if (mileage === '') {
      mileage_invalid.style['display'] = 'block'
    }

    if (zipcode === '') {
      zipcode_blank.style['display'] = 'block'
    }

    if (this.zipcodeNotFound) {
      zipcode_not_found.style['display'] = 'block'
    }

    if (this.checkBlankEmail()) {
      email_blank.style['display'] = 'block'
    } else {
      checkFormatMail = this.validateEmail(email.value)
      if (checkFormatMail)
        email_invalid.style['display'] = 'block'
    }

    if (this.checkBlankFullName()) {
      name_invalid.style['display'] = 'block'
    }

    return (showPopup || checkFormatMail || telError)
  }

  checkBlankEmail() {
    let email = this.emailTarget.value

    return email.length === 0 || this.hasWhiteSpace(email)
  }

  checkBlankFullName() {
    let firstName = this.firstNameTarget.value
    let lastName = this.lastNameTarget.value

    return firstName.length === 0
          || lastName.length === 0
          || this.hasWhiteSpace(firstName)
          || this.hasWhiteSpace(lastName)
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  showPopupError() {
    return this.checkBlankEmail() || this.checkBlankFullName() || this.checkBlankTel()
  }

  checkBlankTel() {
    let tel = this.telTarget.value

    return tel.length === 0 || tel.length === 0
  }

  reSendTelGetSMSCode(event) {
    let self = this
    let telValue = self.telHiddenTarget.value || self.tel
    let resendTarget = self.resendTarget
    let resendText = event.target.className === "btn button__action resend-sms-code" ? "再度、" : ""
    let invalid = self.invalidFeedbackTelTarget

    invalid.style['display'] = 'none'
    $.ajax({
      method: 'POST',
      url: self.smsUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        tel_number: telValue
      },
      success: function(response) {
        if (response?.error?.tel_wrong) {
          invalid.style['display'] = 'block'
          invalid.textContent = response.error.message
        } else if(response?.error?.tel_empty) {
          invalid.style['display'] = 'block'
          invalid.textContent = response.error.message
        }  else {
          resendTarget.textContent = resendText
          if (resendText.length > 0) {
            $('.sms-header-message ').css({'font-weight': 'bold'})
          }
        }
      }
    })
  }

  changeMaker() {
    let self = this
    let makerCode = self.makerTarget.value
    let carModel = $(self.carModelTarget)
    if (makerCode !== '') {
      carModel.prop('disabled', false)

      $.ajax({
        url: self.carModelUrlValue,
        data: { maker_code: makerCode },
        success: function(response) {
          carModel.html(response)
        }
      })
    } else {
      carModel.prop('disabled', true)
      carModel.val('')
    }
  }
}
