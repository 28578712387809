import { Controller } from 'stimulus'
import $ from 'jquery'
import { Suggest } from 'lib/suggest_email'
import { CountDown } from 'module/count_down'

export default class extends Controller {
  static targets = [
    "maker",
    "carModel",
    "modelYear",
    "mileage",
    "pref",
    "modal",
    "modalForm",
    "modalZipcodeForm",
    "zipcode",
    "address",
    "statusCar",
    "email",
    "firstName",
    "lastName",
    "tel",
    "errorMaker",
    "errorCarmodel",
    "errorModelYear",
    "errorMileage",
    "errorZipcode",
    "errorZipcodeNotFound",
    "errorEmail",
    "errorFullName",
    "errorTel",
    "starCar",
    "clientsChecked",
    "modalUserInfo",
    "errorEmailInvalid",
    "errorTelInvalid",
    "carousel",
    "carouselZipcode",
    "modalMaker",
    "modalCarModel",
    "modalYear",
    "modalMileage",
    "modalPref",
    "modalCity",
    "formUserInfo",
    "formStep3",
    "formClients",
    "submit",
    "nextStep",
    "modalDuplicateInquiry",
    "modalClientDuplicateInquiry",
    "duplicateFlag",
    "clientDuplicateFlag",
    "clientChangeRouteFlag",
    "rowlink",
    "footerOverlay",
    "makerCarModel",
    "modalTel",
    "invalidFeedbackTel",
    "invalidFeedbackEmail",
    "modalCode",
    "modalFixedLine",
    "invalidFeedbackCode",
    "numberPhone",
    "code",
    "telHidden",
    "telDuplicate",
    "modalBalloon",
    "form",
    "subLabelZipcode",
    "selectionTypeZipcode",
    "selectionTypePrefCity",
    "prefCity",
    "resend",
    "evalInfo",
    "landingQuestion",
    "showLandingQuestion",
    "modalLandingQuestion0",
    "modalLandingQuestion1",
    "modalLandingQuestion2A",
    "modalLandingQuestion2B",
    "modalLandingQuestion3",
    "modalDoneLanding",
    "modalCancelLanding",
    "modalInternalTwilioError",
    "option2A",
    "option2B",
    "message",
    "submitTelFixedLine",
    "evalInfo",
    "buttonSendInquiry",
    "buttonTelSendInquiry",
    "btnChangePhoneNumber",
    "resendTimer",
    "keyvisualDefault",
    "keyvisualA",
    "keyvisualB",
    "siteLandingOperator",
    "modalCodeRef",
    "refHasCarPrice",
    "refNoCarPrice",
    "refMarkerCar",
    "refPriceMin",
    "refPriceMax",
    "refNameInfo",
    "hiddenFieldAbTest",
    "contactTime",
    "refUnitMaxPrice",
    "modalConfirmInquiryByTelephone"
  ]

  static values = {
    zipcodeUrl: String,
    inquiriesUrl: String,
    carModelUrl: String,
    rootUrl: String,
    userInfoUrl: String,
    confirmUrl: String,
    doneUrl: String,
    carInfoUrl: String,
    prefUrl: String,
    cityUrl: String,
    device: String,
    smsUrl: String,
    codeUrl: String,
    doneLandingUrl: String,
    phoneTypeUrl: String,
    smsRefUrl: String,
    contactTime: String,
    keyVisualFormat: String,
    abtestQuestion0Check: Boolean,
    invalidPreEntry: String
  }

  initialize() {
    this.maker = ''
    this.carModel = ''
    this.modelYear = ''
    this.mileage = ''
    this.statusCarName = $("input[name='car_status']:checked").closest('label').text()
    this.statusCarChecked = $("input[name='car_status']:checked").val()
    this.zipcode = ''
    this.zipcodeNotFound = false
    this.address = ''
    this.email = ''
    this.firstName = ''
    this.lastName = ''
    this.tel = ''
    this.clients = ''
    this.phoneType = ''
    this.maker_title_form = 'メーカーを選択'
    this.car_model_title_form = '車種を選択'
    this.year_title_form = '年式を選択'
    this.mileage_title_form = '走行距離を選択'
    this.blank_step_bar = '未選択'
    this.blank_zipcode = '市区町村が自動入力されます'
    this.blank_pref_city = '選択してください'
    this.marketPrice = ''
    this.requestRunning = false
    this.cookieExpirationDate = 365
    this.countDown = new CountDown(this)
    this.assessmentRoute = 'web'

    this.suggestEmail()
    this.autoShowTelCodeModal().then(() => {this.validateForm()})
    this.loadPrefCity()
    this.showOverlay()
    this.focusBorder()
    this.popupLeavingPage()
    this.checkCookie()
    this.checkCookieKV()
    this.minnayatteruInquiry()
    if (this.deviceValue !== 'pc')
      this.checkPrevModalOffset()
    if (this.invalidPreEntryValue.length > 0) {
      (value => {
        setTimeout(() => {
          alert(value)
        }, 200);
      })(this.invalidPreEntryValue)
    }
  }

  async submitForm(event) {
    event.preventDefault()
    let modal = this.modalTarget
    let validate = this.validateAll()
    if (validate) {
      $(modal).css({ 'display': 'block' })
    }
    else {
      this.sendInquiries(event)
    }
  }

  closeModalCarInfo(event) {
    event.preventDefault()
    $(this.modalTarget).hide()
  }

  closeModalUserInfo(event) {
    event.preventDefault()
    $(this.modalUserInfoTarget).hide()
  }

  changeActive(event) {
    let formEle = $(event.target)[0].closest('div.form__radio')
    let statusName = $(event.target)[0].closest('label.form__radio__label').innerText
    this.statusCarName = statusName
    this.statusCarChecked = event.target.value
    $(this.starCarTargets).removeClass('form__radio__active')
    $(formEle).addClass('form__radio__active')
  }

  changeMaker() {
    let self = this
    let makerCode = self.makerTarget.value
    let carModel = $(self.carModelTarget)
    if (makerCode !== '') {
      carModel.prop('disabled', false)

      $.ajax({
        url: self.carModelUrlValue,
        data: { maker_code: makerCode },
        success: function(response) {
          carModel.html(response)
        }
      })
    } else {
      carModel.prop('disabled', true)
      carModel.val('')
    }
    this.validateForm()
  }

  modalChangeMaker(event, isElement = false, carModelCode = null) {
    let selected_maker = isElement ? event : event.target;

    let makerCode = selected_maker.getAttribute('value');
    let maker_tag = $(this.makerTarget);
    let modalMaker = this.modalMakerTarget;
    let selected_item = modalMaker.getElementsByClassName('selected');
    let car_model_lst = ''
    let car_model_tag = $(this.carModelTarget);
    let modalYear = $(this.modalYearTarget);
    let makerName = selected_maker.textContent;
    let carousel_form = this.carouselTarget;
    let class_maker_code = carousel_form.getElementsByClassName('makerCode');
    let class_car_code = carousel_form.getElementsByClassName('carCode');
    if (selected_maker.classList.contains('selected')) {
      this.applyCarModelCode(carModelCode)
      return
    }

    if (this.deviceValue === 'pc') {
      car_model_lst = $(this.modalCarModelTarget.getElementsByClassName('carModalBox')[0]);
    } else {
      car_model_lst = $(this.modalCarModelTarget.getElementsByClassName('carModelList')[0]);
    }
    let makerCarModel_tag = $(this.makerCarModelTarget);
    let makerCarModel_label = $('#makerCarLabel');

    makerCarModel_tag.children().remove();
    makerCarModel_tag.append(new Option(selected_maker.textContent, makerCode, true))

    maker_tag.children().remove();
    maker_tag.append(new Option(selected_maker.textContent, makerCode, true))
    if (selected_item.length > 0)
      modalMaker.getElementsByClassName('selected')[0].classList.remove('selected');
    selected_maker.classList.add('selected');

    car_model_tag.children().remove();
    car_model_tag.append(new Option('選択してください', ''));

    let self = this
    if (makerCode !== '') {
      $.ajax({
        url: this.carModelUrlValue,
        data: { maker_code: makerCode },
        success: function(response) {
          car_model_tag.closest('.car-model-select').attr('data-bs-slide-to', '1');
          modalYear.find('.carousel-back').attr('data-bs-slide-to', 'prev');

          car_model_lst.children().remove()
          car_model_lst.html(response);

          self.applyCarModelCode(carModelCode)
        }
      })
    } else {
      car_model_lst.val('')
    }

    if (class_maker_code[0])
      for (var i = 0; i < class_maker_code.length; i++) {
        class_maker_code[i].textContent = makerName
        class_car_code[i].textContent = this.blank_step_bar
      }
    makerCarModel_label.removeClass('complete');
    makerCarModel_label[0].textContent = '必須';
    this.validateForm()
  }

  applyCarModelCode(carModelCode) {
    if (carModelCode === null) return

    const carModelEl = document.getElementById('ModalCarModel').querySelector(`ul li[value='${carModelCode}']`)
    this.modalChangeCarModel(carModelEl, true)
  }

  modalChangeCarModel(event, isElement = false) {
    let selected_car_model = isElement ? event : event.target;
    let carModelCode = selected_car_model.getAttribute('value');
    let car_model_tag = $(this.carModelTarget);
    let maker_tag = $(this.makerTarget);
    let maker_select_tag = maker_tag[0]
    let modalCarModel = this.modalCarModelTarget;
    let selected_item = modalCarModel.getElementsByClassName('selected');
    let carModelName = selected_car_model.textContent;
    let carousel_form = this.carouselTarget;
    let class_car_code = carousel_form.getElementsByClassName('carCode');
    let makerCarModel_tag = $(this.makerCarModelTarget);

    let makerSelect = maker_select_tag.length === 2 ? maker_select_tag[1] : maker_select_tag[0]
    let makerCarModel_text = makerSelect.textContent + '  ' + selected_car_model.textContent;
    let makerCarModel_code = makerSelect.value + ',' + carModelCode;

    car_model_tag.children().remove();
    makerCarModel_tag.children().remove();
    car_model_tag.append(new Option(selected_car_model.textContent, carModelCode, true));
    makerCarModel_tag.append(new Option(makerCarModel_text, makerCarModel_code, true));
    car_model_tag.attr('data-bs-slide-to', '1')

    if (selected_item.length > 0)
      modalCarModel.getElementsByClassName('selected')[0].classList.remove('selected');
    selected_car_model.classList.add('selected');
    if (class_car_code[0])
      for (var i = 0; i < class_car_code.length; i++) {
        class_car_code[i].textContent = carModelName
      }

    if (this.validate_modal_inquiry_car_info()) {
      this.close_carousel_modal_form(modalCarModel, 'btn-close')
    }
    this.focusBorder()
    this.validateForm()
    this.balloonZipCode()
  }

  modalChangeYear(event, isElement = false) {
    let selected_year = isElement ? event : event.target;
    let yearCode = selected_year.getAttribute('value');
    let year_tag = $(this.modelYearTarget);
    let modalYear = this.modalYearTarget;
    let selected_item = modalYear.getElementsByClassName('selected');
    let yearName = selected_year.textContent;
    let carousel_form = this.carouselTarget;
    let class_year_code = carousel_form.getElementsByClassName('yearCode');

    if (class_year_code[0])
      for (var i = 0; i < class_year_code.length; i++) {
        class_year_code[i].textContent = yearName
      }
    year_tag.children().remove();
    year_tag.append(new Option(selected_year.textContent, yearCode, true))

    if (selected_item.length > 0)
      modalYear.getElementsByClassName('selected')[0].classList.remove('selected');
    selected_year.classList.add('selected');

    if (this.validate_modal_inquiry_car_info()) {
      this.close_carousel_modal_form(modalYear, 'btn-close')
    }

    this.validateForm()
    this.focusBorder()
  }

  modalChangeMileage(event, isElement = false) {
    let selected_mileage = isElement ? event : event.target;
    let mileageCode = selected_mileage.getAttribute('value');
    let mileage_tag = $(this.mileageTarget);
    let modalMileage = this.modalMileageTarget;
    let selected_item = modalMileage.getElementsByClassName('selected');
    let mileageName = selected_mileage.textContent;
    let carousel_form = this.carouselTarget;
    let class_mileage_code = carousel_form.getElementsByClassName('runDistantCode');
    if (class_mileage_code[0])
      for (var i = 0; i < class_mileage_code.length; i++) {
        class_mileage_code[i].textContent = mileageName
      }
    mileage_tag.children().remove();
    mileage_tag.append(new Option(selected_mileage.textContent, mileageCode, true))

    if (selected_item.length > 0)
      modalMileage.getElementsByClassName('selected')[0].classList.remove('selected');
    selected_mileage.classList.add('selected');

    if (this.validate_modal_inquiry_car_info()) {
      this.close_carousel_modal_form(modalMileage, 'btn-close')
    }
    this.validateForm()
    this.focusBorder()
  }

  validate_modal_inquiry_car_info() {
    let count_maker = this.modalMakerTarget.getElementsByClassName('selected').length;
    let count_car_model = this.modalCarModelTarget.getElementsByClassName('selected').length;
    let count_year = this.modalYearTarget.getElementsByClassName('selected').length;
    let count_mileage = this.modalMileageTarget.getElementsByClassName('selected').length;

    if (count_maker== 0 || count_car_model == 0 || count_year == 0 || count_mileage == 0 ) {
      return false;
    }
    return true
  }

  close_carousel_modal_form(modal, class_name) {
    if (modal.getElementsByClassName(class_name)[0]) {
      modal.getElementsByClassName(class_name)[0].click();
    } else {
      let carousel_form = this.carouselTarget;
      carousel_form.getElementsByClassName('close-form')[0].click();
    }
  }

  modalChangeZipcode(event) {
    let selected_pref = event.target;
    let pref_id = selected_pref.getAttribute('value');
    let modalPref = this.modalPrefTarget;
    let selected_item = modalPref.getElementsByClassName('selected');
    let city_lst = $(this.modalCityTarget.getElementsByClassName('cityList')[0]);
    let prefName = selected_pref.textContent;
    let carousel_form = this.carouselZipcodeTarget;
    let class_pref_code = carousel_form.getElementsByClassName('prefCode');

    if (selected_item.length > 0)
      modalPref.getElementsByClassName('selected')[0].classList.remove('selected');
    selected_pref.classList.add('selected');

    let self = this
    if (pref_id !== '') {
      $.ajax({
        url: self.prefUrlValue,
        data: { pref_id: pref_id },
        success: function(response) {
          city_lst.html(response);
          if (self.deviceValue != 'pc')
            document.querySelector('#headerCity').scrollIntoView({block: "end", behavior: 'smooth', inline: "nearest"})

          self.validateFormUserInfo()
        }
      })
    } else {
      city_lst.val('')
    }

    if (class_pref_code[0])
      for (var i = 0; i < class_pref_code.length; i++) {
        class_pref_code[i].textContent = prefName
      }
  }

  modalChangeCity(event) {
    let self = this
    let selected_city = event.target;
    let modalCity = this.modalCityTarget;
    let cityCode = selected_city.getAttribute('value');
    let selected_item = modalCity.getElementsByClassName('selected');
    let cityName = selected_city.textContent;
    let carousel_form = this.carouselZipcodeTarget;
    let class_city_code = carousel_form.getElementsByClassName('cityCode');
    let modalPref = this.modalPrefTarget;
    let pref_id = modalPref.getElementsByClassName('selected')[0].value;
    let zipcode = this.zipcodeTarget;
    let address_label = this.addressTarget;

    if (class_city_code[0])
      for (var i = 0; i < class_city_code.length; i++) {
        class_city_code[i].textContent = cityName
      }

    if (selected_item.length > 0)
      modalCity.getElementsByClassName('selected')[0].classList.remove('selected');
    selected_city.classList.add('selected');

    if (cityCode !== '' && pref_id !== '') {
      $.ajax({
        url: this.cityUrlValue,
        data: { cityCode: cityCode, prefecture_id: pref_id },
        success: function(response) {
          if (response === undefined || response.address_name.length === 0) {
            zipcode.value = ''
            address_label.textContent = this.blank_zipcode
          } else {
            zipcode.value = response.zipcode
            address_label.textContent = response.address_name
          }
          self.updatePrefectureCity(zipcode.value, address_label.textContent)
          self.zipcodeNotFound = false
          self.validateFormUserInfo()
          if (modalCity.getElementsByClassName('btn-close')[0]) {
            modalCity.getElementsByClassName('btn-close')[0].click();
          } else {
            carousel_form.getElementsByClassName('close-form')[0].click();
          }
          self.focusBorder()
        }
      })
    } else {
      car_model_lst.val('')
    }
    self.focusBorder()
  }

  async checkZipcodeValid() {
    let zipcode = this.zipcodeTarget.value
    let address = this.addressTarget
    let error = false
    if (zipcode.length < 7) {
      this.zipcodeNotFound = true
      address.textContent = this.blank_zipcode
      error = true
    } else {
      let data = await this.changeZipcode(false)
      if (data !== undefined && data.address_name.length > 0) {
        this.zipcodeNotFound = false
      } else {
        this.zipcodeNotFound = true
      }
    }
    return error
  }

  changeZipcode(showAlert = true) {
    let self = this
    let zipcode = self.zipcodeTarget.value
    let address = self.addressTarget
    let regex_zipcode = ''

    if (zipcode === '') {
      address.textContent = this.blank_zipcode
      self.updatePrefectureCity(regex_zipcode, address.textContent)
      self.validateFormUserInfo()
      self.focusBorder()
      return;
    }

    regex_zipcode = zipcode.normalize('NFKC').replace(/[^0-9]/g, "")
    self.zipcodeTarget.value = regex_zipcode
    if (regex_zipcode.length === 7 && !self.requestRunning) {
      self.requestRunning = true
      return $.ajax({
        url: self.zipcodeUrlValue,
        data: { zip_code: regex_zipcode },
        success: function(response) {
          if (response === undefined || response.address_name.length === 0) {
            if (showAlert && !self.zipcodeNotFound) {
              alert('郵便番号−入力された郵便番号の住所が存在しません。')
            }
            self.zipcodeNotFound = true
            address.textContent = self.blank_zipcode
          } else {
            self.zipcodeNotFound = false
            address.textContent = response.address_name
          }
          self.updatePrefectureCity(regex_zipcode, address.textContent)
          self.validateFormUserInfo()
          self.focusBorder()
        },
        error: function() {
          self.requestRunning = false
        }
      })
    } else {
      self.zipcodeNotFound = true
      address.textContent = self.blank_zipcode
      self.updatePrefectureCity(regex_zipcode, address.textContent)
      self.validateFormUserInfo()
      self.focusBorder()
    }
  }

  backForm() {
    window.location.href = this.rootUrlValue
  }

  showPopupError() {
    return this.checkBlankEmail() || this.checkBlankFullName() || this.checkBlankTel()
  }

  checkBlankEmail() {
    let email = this.emailTarget.value

    return email.length === 0 || this.hasWhiteSpace(email)
  }

  checkBlankFullName() {
    let firstName = this.firstNameTarget.value
    let lastName = this.lastNameTarget.value

    return firstName.length === 0
          || lastName.length === 0
          || this.hasWhiteSpace(firstName)
          || this.hasWhiteSpace(lastName)
  }

  checkBlankTel() {
    let tel = this.telTarget.value

    return tel.length === 0 || tel.length === 0
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  checkMailFormat() {
    let email = this.emailTarget.value
    let reMail =  /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/

    if (reMail.test(email)) {
      $(this.errorEmailInvalidTarget).hide()
      return false
    } else {
      $(this.errorEmailInvalidTarget).show()
      return true
    }
  }

  checkTelFormat() {
    let tel = this.telTarget.value
    let reTel = /^(?:\+?\d{1,3}\s*-?)?\(?(?:\d{3})?\)?[- ]?\d{3}[- ]?\d{4}$/
    let tel_format = tel.replace(/\s/g,'')

    if (tel_format.length < 10 || tel_format.length > 11) {
      $(this.errorTelInvalidTarget).show()
      return true
    } else if (reTel.test(tel_format)) {
      $(this.errorTelInvalidTarget).hide()
      return false
    } else {
      $(this.errorTelInvalidTarget).show()
      return true
    }
  }

  editForm() {
    window.location.href = this.rootUrlValue
  }

  sendInquiries(event) {
    event.preventDefault()
    let $btnSendInquiry = $(event.target).parents('.js--btn-wrapper-send-inquiry-click-disable').find('button')
    $btnSendInquiry.attr("disabled", true);
    this.setClientChecked()
    let _event = event
    let phone_type = this.phoneType
    let self = this
    let modal = self.modalDuplicateInquiryTarget
    let modalClient = self.modalClientDuplicateInquiryTarget
    let duplicateFlag = self.duplicateFlagTarget
    let clientDuplicateFlag = self.clientDuplicateFlagTarget
    let clientChangeRouteFlag = self.clientChangeRouteFlagTarget
    let code = self.codeTarget.value
    let invalidCode = self.invalidFeedbackCodeTarget
    let tel = self.tel === '' ? (self.telHiddenTarget.value || self.telTarget.value) : self.tel
    let contactTime = self.contactTime

    self.closeModalEvalInfo()
    let paramsASPTracking = self.getQueryParams(window.location.href)

    $.ajax({
      url: self.inquiriesUrlValue,
      method: 'POST',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        inquiry: {
          client_ids: self.clients,
          duplicateFlag: duplicateFlag.value,
          clientDuplicateFlag: clientDuplicateFlag.value,
          clientChangeRouteFlag: clientChangeRouteFlag.value,
          tel: tel,
          code: code,
          phone_type: phone_type,
          contact_time: contactTime,
          assessment_route: self.assessmentRoute
        },
        ref_code: paramsASPTracking.ref_code,
        utm_source: paramsASPTracking.utm_source,
        utm_medium: paramsASPTracking.utm_medium,
      },
      success: function(response) {
        if (response?.error?.invalid_token) {
          self.checkToken(response)
        } else if (response.error.session_clear) {
          if(window.confirm(response.error.message)) {
            self.backStep1()
          } else {
            $btnSendInquiry.attr("disabled", false);
          }
        } else if (response.error.duplicate) {
          self.closeModalCode()
          self.closeModalTel()
          $btnSendInquiry.attr("disabled", false);
          $(modal).addClass('show-duplicate-popup')
          $(modal).css("z-index", '1050')
          $(duplicateFlag).val(true)
          self.tel = response.error.inquiry.tel
        } else if (response.error.client_duplicate) {
          self.closeModalCode();
          self.closeModalTel();
          $btnSendInquiry.attr("disabled", false);
          $(modalClient).addClass('show-duplicate-popup');
          $(modalClient).css("z-index", '1050');
          $(clientDuplicateFlag).val(true);
          self.message = response.error.message
          if (response.error.client_change_route) {
            $(clientChangeRouteFlag).val(true);
          }
          $('#inquiry-details').html(self.message);
        } else if (response.error.client_empty) {
          $btnSendInquiry.attr("disabled", false);
          alert(response.error.message)
        } else if (response.error.verify_fail) {
          $(modal).removeClass('show-duplicate-popup')
          self.showCodeModal()
          $btnSendInquiry.attr("disabled", false);
          invalidCode.style['display'] = 'block'
          invalidCode.textContent = response.error.message
        } else if (response.error.data_incorrect) {
          $(modal).removeClass('show-duplicate-popup')
          self.showCodeModal()
          $btnSendInquiry.attr("disabled", false);
          invalidCode.style['display'] = 'block'
          invalidCode.textContent = response.error.message
        } else {
          window.location.href = response['redirected_url'];
        }
      },
      error: function (response) {
        $btnSendInquiry.attr("disabled", false);
        for (const [key, value] of Object.entries(response.responseJSON)) {
          alert(`${value.toString()}`);
        }
        window.location.reload()
      }
    })
  }

  closeModalDuplicateInquiry(event) {
    event.preventDefault()
    $(this.modalDuplicateInquiryTarget).removeClass('show-duplicate-popup')
    $(this.duplicateFlagTarget).val(false)
  }

  closeModalClientDuplicateInquiry(event) {
    event.preventDefault()
    $(this.modalClientDuplicateInquiryTarget).removeClass('show-duplicate-popup')
    $(this.clientDuplicateFlagTarget).val(false)
    $(this.clientChangeRouteFlagTarget).val(false)
  }

  backStep1() {
    window.location.href = this.rootUrlValue
  }

  nextStep() {
    this.showButtonHomeSubmit(true)
  }

  setClientChecked() {
    let clientsChecked = []
    this.clientsCheckedTargets.map((check) => {
      if (check.checked) {
        clientsChecked.push(check.value)
      }
    })

    this.clients = clientsChecked
  }

  setCarInquiriesData() {
    this.maker = $('#Maker option:selected').text()
    this.carModel = $('#CarModel option:selected').text()
    this.modelYear = $('#ModelYear option:selected').text()
    this.mileage = $('#Mileage option:selected').text()
    this.address = this.addressTarget.textContent
    this.email = this.emailTarget.value
    this.lastName = this.lastNameTarget.value
    this.firstName = this.firstNameTarget.value
    this.tel = this.telTarget.value
  }

  setUserInquiriesData() {
    this.email = this.emailTarget.value
    this.firstName = this.firstNameTarget.value
    this.lastName = this.lastNameTarget.value
    this.tel = this.telTarget.value
    this.contactTime = $(this.contactTimeTarget).find(`[name='contact_time[time_range]']:checked`).val()
  }

  changeFocus(event) {
    let updateClient = true
    let currentTarget = event.currentTarget.dataset.homeTarget
    const checkboxSubmit = document.getElementById('check-submit');

    let btnInquirySelections = $('.js--btn-inquiry-selection')
    if (currentTarget == 'email' || currentTarget == 'tel') {
      updateClient = false
      btnInquirySelections.prop('disabled', false);
    }

    if (checkboxSubmit && !checkboxSubmit.checked) {
      btnInquirySelections.prop('disabled', false);
    }

    if (event.keyCode == 13) {
      let currentTarget = event.currentTarget.dataset.homeTarget
      if (currentTarget == 'email') {
        this.lastNameTarget.focus()
      } else if (currentTarget == 'lastName') {
        this.firstNameTarget.focus()
      }
    }
    this.validateFormUserInfo(updateClient)
    this.focusBorder()
  }

  validateForm() {
    try {
      let maker = this.makerTarget.value
      let carmodel = this.carModelTarget.value
      let modelyear = this.modelYearTarget.value
      let mileage = this.mileageTarget.value

      let showModalUserInfo = maker != '' && carmodel != '' && modelyear != '' && mileage != ''

      if (showModalUserInfo) {
        this.changeActiveNextButton(true)
      } else {
        this.changeActiveNextButton(false)
        this.showButtonHomeSubmit(false)
        this.disableFormUserInfo()
      }
    }
    catch(err) {}
  }

  changeActiveNextButton(flag) {
    let nextStepButton = this.nextStepTarget

    if (flag === true) {
      nextStepButton.removeAttribute('disabled')
    } else {
      nextStepButton.setAttribute('disabled', true)
    }
    this.showButtonHomeSubmit(flag)
    this.scrollToStatus(flag)
  }

  validateFormUserInfo(updateClient = true) {
    try {
      let zipcode = this.zipcodeTarget.value
      let submitButton= this.submitTarget
      let formClients = this.formClientsTarget

      let lastName = this.lastNameTarget.value
      let firstName = this.firstNameTarget.value

      let formStep3 = this.formStep3Target
      let showStep3 = lastName != '' && firstName != '' && zipcode != '' && this.zipcodeNotFound === false

      if (showStep3) {
        formStep3.style['display'] = 'block'
        this.validateFormStep3(updateClient)
      } else {
        formStep3.style['display'] = 'none'
        formClients.style['display'] = 'none'
        submitButton.setAttribute('disabled', true)
      }
    }
    catch(err){}
  }

  validateFormStep3(updateClient = true) {
    try {
      let submitButton= this.submitTarget
      let formClients = this.formClientsTarget

      let showButtonSubmit = this.hasContactTime()

      if (showButtonSubmit) {
        this.zipcode = this.zipcodeTarget.value
        this.get_client(updateClient)
      } else {
        this.requestRunning = false
        formClients.style['display'] = 'none'
        submitButton.setAttribute('disabled', true)
      }
    }
    catch(err){}
  }

  get_client(updateClient = true) {
    let submitButton= this.submitTarget
    let maker = this.makerTarget.value
    let carmodel = this.carModelTarget.value
    let modelyear = this.modelYearTarget.value
    let mileage = this.mileageTarget.value
    let zipcode = this.zipcodeTarget.value
    let lastName = this.lastNameTarget.value
    let firstName = this.firstNameTarget.value

    let showPopup = maker !== '' && carmodel !== '' && modelyear !== '' && mileage !== '' && zipcode !== ''
                  && lastName !== '' && firstName !== '' && this.zipcodeNotFound === false && this.hasContactTime()

    if (showPopup) {
      submitButton.removeAttribute('disabled')
      this.setCarInquiriesData()
      this.setClientChecked()
      this.setUserInquiriesData()
      this.setUserInfo(updateClient)
    }
  }

  setUserInfo(load_client) {
    let self = this
    $.ajax({
      method: 'POST',
      url: this.userInfoUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        inquiries_user_info: {
          email: self.email,
          firstName: self.firstName,
          lastName: self.lastName,
          tel: self.telHiddenTarget.value,
          contactTime: self.contactTime
        }
      },
      success: function(response) {
        if (response?.error?.invalid_token)
          self.checkToken(response)
        else
          self.setCarInfo(load_client)
      },
      error: function() {
        self.requestRunning = false
      }
    })
  }

  setCarInfo(load_client) {
    let self = this
    let abTestValue = self.hiddenFieldAbTestTarget.value
    let makerCodeValue = self.makerTarget.value
    let carModelCodeValue = self.carModelTarget.value
    let makerCarModelName = ''
    if (makerCodeValue.length == 0 || carModelCodeValue.length == 0) return
    if (self.carModelTarget.length > 1)
      makerCarModelName = self.carModelTarget[1].textContent
    else
      makerCarModelName = self.carModelTarget[0].textContent
    $.ajax({
      method: 'POST',
      url: self.carInfoUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        inquiries_car_info: {
          master_maker_code: makerCodeValue,
          master_car_model_code: carModelCodeValue,
          master_model_year_code: self.modelYearTarget.value,
          master_mileage_code: self.mileageTarget.value,
          car_status: self.statusCarChecked,
          maker_car_model_code: self.makerCarModelTarget.value,
          maker_car_model_text: self.makerCarModelTarget.textContent,
          maker_car_model_name: makerCarModelName,
          zipcode: self.zipcode,
          address: self.address,
          ab_test: abTestValue
        }
      },
      success: function(response) {
        if (load_client == true) {
          if (response?.error?.invalid_token)
            self.checkToken(response)
          else
            self.showClientList(load_client)
        }
      },
      error: function() {
        self.requestRunning = false
      }
    })
  }

  showClientList() {
    let submitButton= this.submitTarget
    let formClients = this.formClientsTarget
    let self = this
    let client_lst = $(self.formClientsTarget.getElementsByClassName('clientList')[0]);
    $.ajax({
      method: 'POST',
      url: self.confirmUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content
      },
      success: function(response) {
        if (response.error) {
          if (response?.error?.invalid_token) {
            self.checkToken(response)
          } else if(response.error.data_incorrect) {
            alert(response.error.message);
          } else {
            if(window.confirm(response.message)) {
              self.backStep1()
            }
          }
        } else {
          submitButton.removeAttribute('disabled')
          formClients.style['display'] = 'block'
          client_lst.children().remove()
          client_lst.html(response);
        }
      },
      error: function (request, status, error) {
        let message = JSON.parse(request.responseText)['message']
        alert(message)
        window.location.reload()
      },
      complete: function() {
        self.requestRunning = false
      }
    })
  }

  validateAll() {
    let maker = this.makerTarget.value
    let carmodel = this.carModelTarget.value
    let modelyear = this.modelYearTarget.value
    let mileage = this.mileageTarget.value
    let zipcode = this.zipcodeTarget.value
    let email = this.emailTarget
    let lastName = this.lastNameTarget.value
    let firstName = this.firstNameTarget.value
    let checkFormatMail


    let showPopup = maker === '' || carmodel === '' || modelyear === '' || mileage === '' || zipcode === ''
                  || email === '' || lastName === '' || firstName === ''
                  || this.zipcodeNotFound || this.showPopupError()

    if (maker === '') {
      $(this.errorMakerTarget).show()
    } else {
      $(this.errorMakerTarget).hide()
    }
    if (carmodel === '') {
      $(this.errorCarmodelTarget).show()
    } else {
      $(this.errorCarmodelTarget).hide()
    }
    if (modelyear === '') {
      $(this.errorModelYearTarget).show()
    } else {
      $(this.errorModelYearTarget).hide()
    }
    if (mileage === '') {
      $(this.errorMileageTarget).show()
    } else {
      $(this.errorMileageTarget).hide()
    }

    if (zipcode === '') {
      $(this.errorZipcodeTarget).show()
    } else {
      $(this.errorZipcodeTarget).hide()
    }

    if (this.zipcodeNotFound) {
      $(this.errorZipcodeNotFoundTarget).show()
    } else {
      $(this.errorZipcodeNotFoundTarget).hide()
    }

    if (this.checkBlankEmail()) {
      $(this.errorEmailTarget).show()
    } else {
      checkFormatMail = this.checkMailFormat()
      $(this.errorEmailTarget).hide()
    }

    if (this.checkBlankFullName()) {
      $(this.errorFullNameTarget).show()
    } else {
      $(this.errorFullNameTarget).hide()
    }

    return (showPopup || checkFormatMail || checkFormatTel)
  }

  showButtonHomeSubmit(show = false) {
    let submitButton= this.submitTarget
    let nextStepButton= this.nextStepTarget
    let formUserInfo = this.formUserInfoTarget
    let formClients = this.formClientsTarget
    let formStep3   = this.formStep3Target

    if (show) {
      nextStepButton.style['display'] = 'none'
      submitButton.style['display'] = 'block'
      formUserInfo.style['display'] = 'block'
      formStep3.style['display'] = 'block'
      formClients.style['display'] = 'block'
      this.validateFormUserInfo()
      this.balloonZipCode()
      this.balloonTel()
    } else {
      nextStepButton.style['display'] = 'block'
      submitButton.style['display'] = 'none'
      formUserInfo.style['display'] = 'none'
      formStep3.style['display'] = 'none'
      formClients.style['display'] = 'none'
    }
  }

  suggestEmail() {
    if ($("#Email").length)
      new Suggest.Local("Email", "emailList", []);
  }

  activeRowLink(event) {
    let rowlinkCurrent= $(event.currentTarget).children()[0].getAttribute('href')

    $(this.rowlinkTargets).each(function(index, value) {
      if (rowlinkCurrent === value.getAttribute('href')) {
        $(this).addClass('active-row-link');
      } else {
        $(this).removeClass('active-row-link');
      }
    });
  }

  showOverlay() {
    if (this.hasFooterOverlayTarget) {
      let footer_overlay = this.footerOverlayTarget

      $(window).scroll(() => {
        if (this.isInView($('#appeal'))) {
          footer_overlay.style['display'] = 'flex'
        } else {
          footer_overlay.style['display'] = 'none'
        }
      })
    }
  }

  isInView(elem){
    if (elem.offset()) return elem.offset()['top'] - window.innerHeight < window.scrollY ;
    return false
  }

  scrollToStatus(flag) {
    if (flag === true) {
      var statusTop = $('#Step2Form').offset()['top']; //Getting Y of target element
      window.scrollTo(0, statusTop);
    }
  }

  focusBorder() {
    if (this.hasZipcodeTarget) {
      let requiredMap = [
        {src: "#MakerCarModel", view: ".makerModal", label: '#makerCarLabel'},
        {src: "#ModelYear", view: ".modelYearModal", label: '#yearLabel'},
        {src: "#Mileage", view: ".mileageModal", label: '#mileageLabel'},
        {src: "#ZipCode", view: ".zipcodeModal", label: '#zipCodeLabel'},
        {src: "#LastName", view: ".lastNameModal", label: '#fullNameLabel'},
        {src: "#FirstName", view: ".firstNameModal", label: '#fullNameLabel'},
        {src: "#PrefCity", view: ".prefCityModal", label: '#prefCityLabel'},
        {src: "#Email", view: ".emailModal", label: '#emailLabel'},
        {src: "#TelModal", view: ".telModal", label: '#telLabel'},
      ];
      let viewSelector = requiredMap.map((t) => { return t.view }).join(",");

      $(viewSelector).removeClass("focusBorder");
      let getFirstNoInput = function() {
        for(var i = 0; i < requiredMap.length; i++) {
          let require = $(requiredMap[i].src);
          if(require.val() == '') {
            return $(requiredMap[i].view);
          }
        }
        return null;
      }
      let f = getFirstNoInput();
      if(f != null) {
        f.addClass("focusBorder");
      }

      this.change_label_require(requiredMap)
    }
  }

  change_label_require(requiredMaps) {
    requiredMaps.forEach(requiredMap => {
      let require = $(requiredMap.src);
      let label = $(requiredMap.label);
      if ((require.val() !== '') && (require.val() !== undefined)) {
        label.addClass('complete');
        label[0].textContent = 'OK';
      } else {
        label.removeClass('complete');
        label[0].textContent = '必須';
      }
    })
  }

  replaceTel(tel) {
    let telTarget = this.telTarget
    telTarget.value = tel.normalize('NFKC').replace(/[ー - − -]/g, '');
  }

  async showTelModal(e) {
    let $buttonSubmit = undefined
    if(e && e.type === 'click') {
      $buttonSubmit = $(e.target.closest('.button__submit'))
      $buttonSubmit.attr('disabled', true)
    }

    this.setClientChecked()
    let modal = this.modalTelTarget
    let telTarget = this.telTarget
    let self = this;

    telTarget.removeAttribute('readonly')
    await $.ajax({
      method: 'GET',
      url: '/inquiries/ajax_build_buttons_modal_sms_email_inquiry',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        inquiry: {
          client_ids: self.clients
        }
      },
      success: function(response) {
        $('.js--render-buttons-group-web-telephone-inquiry').html(response);
        const checkboxSubmit = document.getElementById('check-submit');
        if (checkboxSubmit) {
          checkboxSubmit.checked = false;
          let buttons = $('.js--render-buttons-group-web-telephone-inquiry button')
          buttons.prop('disabled', true);
        }
        $(modal).addClass('show-tel-code-popup')
        self.countDown.stop()
      },
      error: function (request, status, error) {
        let message = JSON.parse(request.responseText)['message']
        alert(message)
        window.location.reload()
      },
      complete: function () {
        if($buttonSubmit !== undefined) {
          $buttonSubmit.attr('disabled', false)
        }
      }
    })
  }

  closeModalTel() {
    let modal = this.modalTelTarget
    let invalid = this.invalidFeedbackTelTarget
    if(this.assessmentRoute === 'web') {
      this.telTarget.value = ''
    }
    invalid.style['display'] = 'none'
    $(modal).removeClass('show-tel-code-popup')
  }

  getInfoSmsRef() {
    let self = this
    return $.ajax({
      method: 'POST',
      url: self.smsRefUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content
      },
      success: function(response) {
        return response;
      },
      error: function(response){
        for (const [key, value] of Object.entries(response.responseJSON)) {
          alert(`${value.toString()}`);
        }
      }
    })
  }

  async showCodeModal(e) {
    if(this.assessmentRoute === 'telephone' || e && e.type === 'click') {
      this.showTelModal()
      return false
    }

    let modal
    let refHasCarPrice
    let refNoCarPrice
    let refUnitMaxPrice
    let marketPriceMax
    let marketPriceMin
    let originalMarketPriceMin
    let originalMarketPriceMax
    let numberPhone = this.numberPhoneTarget
    let fortmatTel = this.formatNumberPhone()
    let phone_type = await this.checkPhoneType(fortmatTel)

    if (this.hasModalCodeTarget) modal = this.modalCodeTarget
    else {
      let infoSMSRef = await this.getInfoSmsRef()
      modal = this.modalCodeRefTarget
      refHasCarPrice = this.refHasCarPriceTarget
      refNoCarPrice = this.refNoCarPriceTarget
      refUnitMaxPrice = this.refUnitMaxPriceTarget
      marketPriceMax = infoSMSRef["market_price_max"]
      marketPriceMin = infoSMSRef["market_price_min"]
      originalMarketPriceMin = infoSMSRef['original_market_price_min']
      originalMarketPriceMax = infoSMSRef['original_market_price_max']
      let invalidMaxPrice = $(this.refPriceMaxTarget).data('invalid-price')
      this.refNameInfoTarget.textContent = infoSMSRef["last_name"] + "様の" + infoSMSRef["maker_car_model_name"] +"の"
      if (marketPriceMax === 0 && marketPriceMin === 0 || originalMarketPriceMin === invalidMaxPrice && originalMarketPriceMax === invalidMaxPrice) {
        $(refHasCarPrice).addClass('d-none');
        $(refNoCarPrice).removeClass('d-none');
        $(refUnitMaxPrice).removeClass('d-none');
        $(this.refPriceMaxTarget).addClass('body-content__text-subtitle eval-info-subtitle')
      } else if(originalMarketPriceMax === invalidMaxPrice){
        this.refMarkerCarTarget.textContent = infoSMSRef["maker_car_model_name"] + 'の相場価格は'
        this.refPriceMinTarget.textContent = infoSMSRef["market_price_min"]
        this.refPriceMaxTarget.textContent = 'です'
        $(this.refPriceMaxTarget).removeClass('body-content__text-subtitle eval-info-subtitle')
        $(refHasCarPrice).removeClass('d-none');
        $(refNoCarPrice).addClass('d-none');
        $(refUnitMaxPrice).addClass('d-none');
      } else {
        $(refHasCarPrice).removeClass('d-none');
        $(refNoCarPrice).addClass('d-none');
        $(refUnitMaxPrice).removeClass('d-none');
        $(this.refPriceMaxTarget).addClass('body-content__text-subtitle eval-info-subtitle')
        this.refMarkerCarTarget.textContent = infoSMSRef["maker_car_model_name"] + 'の相場価格は'
        this.refPriceMinTarget.textContent = infoSMSRef["market_price_min"]
        this.refPriceMaxTarget.textContent = infoSMSRef["market_price_max"]
      }

      if(infoSMSRef["market_price_min"] === 0) {
        $('.modal-tel-code.modal-sms-code .js--unit-market-price-min').hide()
      } else {
        $('.modal-tel-code.modal-sms-code .js--unit-market-price-min').show()
      }
    }

    if (phone_type["phone_type"] != "mobile") {
      modal = this.modalTelTarget
      let telTarget = this.telTarget
      telTarget.removeAttribute('readonly')
      telTarget.value = fortmatTel
    }

    $(numberPhone).text(fortmatTel)
    $(modal).addClass('show-tel-code-popup')
  }

  closeModalCode() {
    const modal = (this.hasModalCodeTarget ? this.modalCodeTarget : this.modalCodeRefTarget)
    let invalid = this.invalidFeedbackCodeTarget
    let resendTarget = this.resendTarget
    this.telTarget.value = ''
    this.tel = ''
    invalid.style['display'] = 'none'
    $(modal).removeClass('show-tel-code-popup')
    resendTarget.textContent = ''
    $('.sms-header-message ').css({'font-weight': ''})
  }

  formatNumberPhone() {
    let tel = this.tel.length > 0 ? this.tel : this.telHiddenTarget.value
    this.replaceTel(tel)
    let formatTel = ''
    if (tel.length === 0)
      return

    if (tel.length > 10) {
      formatTel = tel.replace(/\D/g, '').match(/(\d{3})(\d{4})(\d{4})/)
    } else {
      formatTel = tel.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/)
    }
    if (!!formatTel) {
      return formatTel[1] + '-' + formatTel[2] + '-' + formatTel[3]
    }

    return ''
  }

  editTel() {
    this.tel = ''
    this.telTarget.value = ''
    this.telHiddenTarget.value = ''
    this.codeTarget.value = ''
    this.closeModalCode()
    this.showTelModal()
  }

  async validateTel(event, invalidEmail = false) {
    let tel = this.telTarget.value
    let telTarget = this.telTarget
    this.tel = tel
    telTarget.setAttribute('readonly', true)
    let invalid = this.invalidFeedbackTelTarget
    let reTel = /^(?:\+?\d{1,3}\s*-?)?\(?(?:\d{3})?\)?[- ]?\d{3}[- ]?\d{4}$/
    let tel_format = tel.replace(/\s/g,'')
    let reTelFWidth = /\s/g
    tel.normalize('NFKC').replace(/[ー - − -]/g, '');

    function invalidTelFormat() {
      return !reTel.test(tel_format) || reTelFWidth.test(tel_format);
    }

    function invalidTelLength() {
      return tel_format.length < 10 || tel_format.length > 11;
    }

    // invalid tel client checking
    if (invalidTelFormat()) {
      invalid.style['display'] = 'block'
      invalid.textContent = '正しい携帯電話番号を入力してください'
      telTarget.removeAttribute('readonly')
      return false
    } else if (invalidTelLength()) {
      invalid.style['display'] = 'block'
      invalid.textContent = '連絡先(TEL)番号は数値10桁もしくは11桁で入力してください。'
      telTarget.removeAttribute('readonly')
      return false
    }

    let phone_type = await this.checkPhoneType(tel_format)
    // invalid tel checking from server
    if (phone_type?.error?.invalid_phone) {
      invalid.style['display'] = 'block'
      invalid.textContent = phone_type.error.message
      telTarget.removeAttribute('readonly')
      return false
    }

    if (invalidEmail) {
      telTarget.removeAttribute('readonly')
      return false
    }

    this.phoneType = phone_type = phone_type["phone_type"]
    if (phone_type === 'fixed_line'){
      invalid.style['display'] = 'none'
      invalid.textContent = ''
      telTarget.removeAttribute('readonly')
      this.telHiddenTarget.value = tel_format
      this.tel = tel_format
      this.telTarget.value = tel_format
      this.setUserInfo(false)
      if(this.assessmentRoute === 'web'){
        this.sendInquiries(event)
      } else if (this.assessmentRoute === 'telephone') {
        this.closeModalTel()
        this._showModalConfirmInquiryByTelephone()
      }
      return true
    } else if (phone_type === 'toll_free') {
      invalid.style['display'] = 'block'
      invalid.textContent = 'フリーダイヤルでのお問い合わせは受け付けておりません。フリーダイヤル以外の電話番号をご入力ください。'
      telTarget.removeAttribute('readonly')
      return false
    } else if (phone_type === 'mobile') {
      invalid.style['display'] = 'none'
      invalid.textContent = ''
      this.telHiddenTarget.value = tel_format
      if(this.assessmentRoute === 'web'){
        await this.sendTelGetSMSCode(event)
      } else if (this.assessmentRoute === 'telephone') {
        this.closeModalTel()
        this._showModalConfirmInquiryByTelephone()
      }
      return true
    }
  }

  showModalInternalTwilioError() {
    this.modalInternalTwilioErrorTarget.classList.add('open')
  }

  closeModalInternalTwilioError() {
    this.modalInternalTwilioErrorTarget.classList.remove('open')
  }

  async sendTelGetSMSCode(event) {
    let self = this
    let invalid = self.invalidFeedbackTelTarget
    let telTarget = self.telTarget
    let telValue = self.tel.length > 0 ? self.tel : self.telHiddenTarget.value
    let resendTarget = self.resendTarget
    let resendText = event.target.className === "btn button__action resend-sms-code" ? "再度、" : ""

    await $.ajax({
      method: 'POST',
      url: self.smsUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        tel_number: telValue
      },
      success: function(response) {
        if (response?.internal_twilio_error) {
          self.closeModalTel()
          self.closeModalCode()
          self.showModalInternalTwilioError()
          return
        }

        if (response?.error?.invalid_token) {
          self.checkToken(response)
        } else if (response?.error?.tel_wrong) {
          self.closeModalCode()
          self.showTelModal()
          invalid.style['display'] = 'block'
          invalid.textContent = response.error.message
          telTarget.removeAttribute('readonly')
        } else if(response?.error?.tel_empty) {
          self.closeModalCode()
          self.showTelModal()
          invalid.style['display'] = 'block'
          invalid.textContent = response.error.message
          telTarget.removeAttribute('readonly')
        }  else {
          self.telTarget.value = telTarget.value
          telTarget.removeAttribute('readonly')
          resendTarget.textContent = resendText
          if (resendText.length > 0) {
            $('.sms-header-message ').css({'font-weight': 'bold'})
          }
          self.setUserInfo(false)
          self.closeModalTel()
          self.showCodeModal()
          if (self.hasResendTimerTarget) self.countDown.start()
        }
      }
    })
  }

  changeGlobalFirstNumber() {
    let first_number = this.telTarget.value.slice(0,1).replace('0', '+84')
    let last_number = this.telTarget.value.slice(1)
    return first_number+last_number
  }

  async autoShowTelCodeModal() {
    if (this.hasZipcodeTarget) {
      let zipcode = this.zipcodeTarget.value

      if (zipcode.length === 0)
        return

      this.telTarget.value = ''
      await this.showTelModal()
    }
  }

  submitCodeWithKeyboard(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.sendInquiries(event)
    }
  }

  popupLeavingPage() {
    if (this.hasFormUserInfoTarget) {
      let formUserInfo = this.formUserInfoTarget
      let formStep3 = this.formStep3Target
      let modalBalloon = this.modalBalloonTarget
      let formTarget = this.formTarget

      let maker = this.makerTarget
      let carModel = this.carModelTarget
      let modelYear = this.modelYearTarget
      let mileage = this.mileageTarget

      window.history.pushState(null, "", window.location.href);
      window.addEventListener('popstate', function(event) {
        let $modalBalloon =  $(modalBalloon)

        if (window.location.hash !== '') {
          return event.preventDefault()
        }

        if ($modalBalloon.attr("is-show") === 'true') {
          window.history.pushState(null, "", window.location.href);
          $modalBalloon.attr("is-show", false);

          if ($(formUserInfo).css('display') === 'block') {
            $('.balloon-block .chat-operator__text').html(
                'あと<span class="balloon-block__highlight">5秒もかからず査定完了</span>です！<br>他社にはない査定体験を、ご確認ください！'
            )
          }else if ($(formStep3).css('display') === 'block') {
            $('.balloon-block .chat-operator__text').html(
                'あと<span class="balloon-block__highlight">5秒もかからず査定完了</span>です！<br>他社にはない査定体験を、ご確認ください！'
            )
          } else if (maker.value !== '' || carModel.value !== '' || modelYear.value !== '' || mileage.value !== '') {
            $('.balloon-block .chat-operator__text').html(
                '残り僅かなステップで<span class="balloon-block__highlight">査定完了</span>です。<br>他社との相見積もりのつもりでぜひ入力を！'
            )
          }

          $('html, body').animate({
            scrollTop: $(formTarget).offset().top
          }, 10);
          $modalBalloon.modal('show')

        } else {
          history.back()
        }
      });
    }
  }

  balloonTel() {
    let firstName = this.firstNameTarget.value
    let lastName = this.lastNameTarget.value
    lastName = $('<div/>').text(lastName).html();

    if (firstName !== '' && lastName !== '') {
      $('.remind_fill_tel').removeClass('d-none')
      $('.remind_fill_tel .chat-operator__text').html(
        `お見積りをお伝えする連絡先を入力して下さい。`
      )
    } else {
      $('.remind_fill_tel').addClass('d-none')
    }
  }

  balloonZipCode() {
    let carModalLabel = $(this.carModelTarget).find('option:selected').text()
    $('#Step2Form .chat-operator__text').html(
        `<strong>${carModalLabel}</strong>の最新の相場額をWEB上でチェックできます！`
    )
  }

  showEvalInfo(event) {
    let modal = this.evalInfoTarget;
    let clientsChecked = [];

    this.clientsCheckedTargets.map((check) => {
      if (check.checked) {
        clientsChecked.push(check.dataset.clientName)
      }
    })

    if (clientsChecked.length === 0 || this.statusCarChecked == 1) {
      this.sendInquiries(event);
    } else {
      this.closeModalCode();
      $(modal).find('span.client-name').html(clientsChecked[0]);
      $(modal).addClass('show-tel-code-popup');
    }
  }

  closeModalEvalInfo() {
    let modal = this.evalInfoTarget
    $(modal).removeClass('show-tel-code-popup')
  }

  validateEmail(email) {
    let invalid = this.invalidFeedbackEmailTarget

    let validEmail = email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (validEmail == null) {
      invalid.style['display'] = 'block'
      invalid.textContent = '正しいメールアドレスを入力してください'
      return false
    }

    invalid.style['display'] = 'none'
    invalid.textContent = ''
    return true
  }

  checkPhoneType(tel_number) {
    let self = this
    return $.ajax({
      method: 'POST',
      url: self.phoneTypeUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        tel_number: tel_number
      },
      success: function(response) {
        if (response?.error?.invalid_token)
          self.checkToken(response)
        if (response['phone_type'] != null) {
          return response['phone_type']
        }
      }
    })
  }

  submitPhone(event, assessmentRoute='web') {
    this.assessmentRoute = assessmentRoute
    let $btnSendInquiry = $(event.target).closest('.js--btn-wrapper-send-inquiry-click-disable').find('button')
    let mailText = this.emailTarget.value
    let telNum = this.telTarget.value
    $btnSendInquiry.prop('disabled', true);
    this.replaceTel(telNum)
    let disabledButton = false
    let invalidEmail = false

    let self = this
    if (event.keyCode === 13 || event.type === 'click') {
      Promise.resolve(this.validateEmail(mailText)).then(function(response){
        if(!response) {
          invalidEmail = true
          disabledButton = true
        }

        Promise.resolve(self.validateTel(event, invalidEmail)).then(function(response){
          if(!response) {
            disabledButton = true
          }
        })

        $btnSendInquiry.prop('disabled', disabledButton);
      })
    }
  }

  loadPrefCity() {
    if (this.hasZipcodeTarget) {
      let zipcode = this.zipcodeTarget.value
      let address = this.addressTarget.textContent
      if (zipcode != "") {
        this.updatePrefectureCity(zipcode, address)
      }
    }
  }

  updatePrefectureCity(zipcode, address) {
    let subLabelZipcode = this.subLabelZipcodeTarget;
    let prefCity = $(this.prefCityTarget);

    subLabelZipcode.textContent = zipcode === "" ? "" : "〒" + zipcode
    address = address === this.blank_zipcode ? this.blank_pref_city : address
    prefCity.children().remove();
    prefCity.append(new Option(address, zipcode, true))
  }

  changeSelectionTypeZipcode(event) {
    event.preventDefault()
    let current_target = event.currentTarget.dataset.bsTarget

    if (current_target === "#modalZipcodeSelect") {
      this.selectionTypeZipcodeTarget.style['display'] = 'none'
      this.selectionTypePrefCityTarget.style['display'] = 'flex'
    }else {
      this.selectionTypeZipcodeTarget.style['display'] = 'flex'
      this.selectionTypePrefCityTarget.style['display'] = 'none'
    }
  }

  setCookie(c_name,value,exdays) {
    let exdate=new Date();
    exdate.setDate(exdate.getDate() + exdays);
    let c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
    document.cookie=c_name + "=" + c_value;
  }

  getCookie(c_name){
    let c_value = document.cookie;
    let c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1){
      c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start == -1){
      c_value = null;
    } else{
      c_start = c_value.indexOf("=", c_start) + 1;
      let c_end = c_value.indexOf(";", c_start);
      if (c_end == -1){
        c_end = c_value.length;
      }
      c_value = unescape(c_value.substring(c_start,c_end));
    }
    return c_value;
  }

  checkCookie(){
    if (!this.hasLandingQuestionTarget) return
    let c = this.getCookie("visited");
    if (c != "yes") {
      this.showLandingQuestion()
    }
    this.setCookie("visited", "yes", this.cookieExpirationDate); // expire in 1 year;
 }

  showLandingQuestion() {
    let modal = this.modalLandingQuestion1Target

    if (this.abtestQuestion0CheckValue) {
      modal = this.modalLandingQuestion0Target
    }

    $(modal).show()
  }

  showLandingQuestionModal(event) {
    let currentTarget = event.currentTarget
    let modal0 = this.abtestQuestion0CheckValue && this.modalLandingQuestion0Target
    let modal1 = this.modalLandingQuestion1Target
    let modal2A = this.modalLandingQuestion2ATarget
    let modal2B = this.modalLandingQuestion2BTarget
    let targetShow = currentTarget.dataset.homeTarget
    let curent_modal = modal1
    let modal = ""

    if (targetShow === "option2A") {
      this.question1 = "car_unnecessary"
      modal = modal2A
    } else if (targetShow === "option2B") {
      this.question1 = "considering_transfer"
      modal = modal2B
    } else if (this.abtestQuestion0CheckValue && targetShow === 'option1' ) {
      curent_modal = modal0
      modal = modal1
    } else {
      curent_modal = targetShow === "option1A" ? modal2A : modal2B
      modal = modal1
    }
    $(curent_modal).hide()
    $(modal).show()
  }

  showLandingQuestionModalWithRefCode(event) {
    let currentTarget = event.currentTarget
    let modal1 = this.modalLandingQuestion1Target
    let modal2A = this.modalLandingQuestion2ATarget
    let modal2B = this.modalLandingQuestion2BTarget
    let modal3 = this.modalLandingQuestion3Target
    let targetShow = currentTarget.dataset.homeTarget
    let curent_modal = modal1
    let modal = ""

    switch (targetShow) {
      case 'option2A':
        this.question1 = "car_unnecessary"
        modal = modal2A
        break;
      case 'option2B':
        this.question1 = "considering_transfer"
        modal = modal2B
        break;
      case 'option3A':
        this.current_modal = modal2A
        this.question2a = currentTarget.dataset["question-2a"]
        this.question2b = ''
        modal = modal3
        break;
      case 'option3B':
        this.current_modal = modal2B
        this.question2b = currentTarget.dataset["question-2b"]
        this.question2a = ''
        modal = modal3
        break;
      case 'option2':
        curent_modal = modal3
        modal = this.current_modal
        break;
      default:
        curent_modal = targetShow === "option1A" ? modal2A : modal2B
        modal = modal1
    }

    $(curent_modal).hide()
    $(modal).show()
  }

  closeLandingQuestion1Modal() {
    if (this.keyVisualFormatValue === 'new') {
      this.setCookie("site_landing", "question-2b", this.cookieExpirationDate);
      this.checkCookieKV()
    }

    let landingQuestion1Target = this.modalLandingQuestion1Target
    $(landingQuestion1Target).hide()
  }

  sendLandingQuestion(event) {
    let dataset = event.currentTarget.dataset
    let answer_1_value = this.question1
    let self = this
    let answer_2a_value = ''
    let answer_2b_value = ''

    if (dataset['question-2a']) {
      answer_2a_value = dataset['question-2a']
      self.setCookie("site_landing", "question-2a", this.cookieExpirationDate);
    } else {
      answer_2b_value = dataset['question-2b']
      self.setCookie("site_landing", "question-2b", this.cookieExpirationDate);
    }

    $.ajax({
      method: 'POST',
      url: self.doneLandingUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        landing_questions: {
          question_1: answer_1_value,
          question_2a: answer_2a_value,
          question_2b: answer_2b_value
        }
      },
      success: function(response) {
        if (response?.error) {
          if (response?.error?.invalid_token) {
            self.checkToken(response)
          } else if (response?.error?.session_clear) {
            if(window.confirm(response?.error?.message)) {
              // clear cookie and reload page if there are any errors
              self.setCookie("visited", "no", this.cookieExpirationDate);
              self.backStep1()
            }
          }
        }

        self.showModalDone()
        self.checkCookieKV()
      }
    })
  }

  sendLandingQuestionWithRefCode(event) {
    let dataset = event.currentTarget.dataset
    let answer_1_value = this.question1
    let self = this
    let answer_2a_value = this.question2a || ''
    let answer_2b_value = this.question2b || ''
    let answer_3_value = dataset['question-3']

    $.ajax({
      method: 'POST',
      url: self.doneLandingUrlValue,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        landing_questions: {
          question_1: answer_1_value,
          question_2a: answer_2a_value,
          question_2b: answer_2b_value,
          question_3: answer_3_value
        }
      },
      success: function(response) {
        if (response?.error) {
          if (response?.error?.invalid_token) {
            self.checkToken(response)
          } else if (response?.error?.session_clear) {
            if(window.confirm(response?.error?.message)) {
              // clear cookie and reload page if there are any errors
              self.setCookie("visited", "no", this.cookieExpirationDate);
              self.backStep1()
            }
          }
        }

        self.showModalDone()
        self.checkCookieKV()
        if (answer_3_value === 'in_3_months_or_more') self.afterChooseQuestion3()
      }
    })
  }

  showModalDone() {
    let doneTarget = this.modalDoneLandingTarget;
    $(doneTarget).show();

    setTimeout(() => this.closeLandingQuestionModal(), 3000);
  }

  showCancelLandingModal() {
    let cancelTarget = this.modalCancelLandingTarget;
    $(cancelTarget).show();

    setTimeout(() => {
      this.closeLandingQuestionModal()
      $('html, body').animate({
        scrollTop: $("#form_search_the_purchase_price_of_your_car").offset().top
      });
    }, 3000);
  }

  closeLandingQuestionModal() {
    let landingQuestionForm = this.landingQuestionTarget
    landingQuestionForm.style['display'] = 'none'
  }

  checkToken(response) {
    alert(response.error.message)
    return this.backStep1()
  }

  // check Cookie Keyvisual
  checkCookieKV(){
    let c = this.getCookie("site_landing");
    if (c === null)
      c = 'default'

    this.showKeyVisual(c)
  }

  showKeyVisual(question) {
    if (!this.hasKeyvisualDefaultTarget) return
    let keyVisuals = [
      {target: this.keyvisualDefaultTarget, question: "default"},
      {target: this.keyvisualATarget, question: "question-2a"},
      {target: this.keyvisualBTarget, question: "question-2b"}
    ];
    keyVisuals.forEach(keyVisual => {
      let require = keyVisual.question;
      let require_target = keyVisual.target;
      if (question  == require)
        require_target.style['display'] = 'block'
      else
        require_target.style['display'] = 'none'
    })
  }

  hasContactTime() {
    if (this.contactTimeValue === 'new') {
      return  $(this.contactTimeTarget).find("[name='contact_time[time_range]']:checked").val() !== undefined
    } else {
      return true
    }
  }

  checkPrevModalOffset() {
    $('.carousel-control').click(function(e){
      let perentElement = document.querySelector('#carouselZipcodeModal')
                              .getElementsByClassName('selected')[0].parentElement
      perentElement.scrollIntoView({block: "center", inline: "nearest"})
    });
  }

  afterChooseQuestion3() {
    $('html, body').animate({
      scrollTop: $("#form_search_the_purchase_price_of_your_car").offset().top
    }, 1000);

    $('#scheduledSaleTime3').parent().trigger( "click" )
  }

  closeModal(e) {
    let $modal = $(e.target.closest('.modal'))
    $modal.removeClass('display-block')
  }

  actionInquiryByTelephone(event){
    this.submitPhone(event, 'telephone')
  }

  _showModalConfirmInquiryByTelephone() {
    let $modalConfirmInquiryByTelephone = $(this.modalConfirmInquiryByTelephoneTarget)
    let clientName = this.clientsCheckedTargets[0].dataset['clientName']
    $('#modal-confirm-inquiry-telephone .js--client-name').text(clientName)
    $modalConfirmInquiryByTelephone.addClass('display-block')
  }

  _hideModalConfirmInquiryByTelephone() {
    let $modalConfirmInquiryByTelephone = $(this.modalConfirmInquiryByTelephoneTarget)
    $modalConfirmInquiryByTelephone.removeClass('display-block')
  }
  checkButtonSubmitStep3() {
    const checkBox = $("#check-submit");
    let buttons = $('.js--render-buttons-group-web-telephone-inquiry button')
    if (checkBox.prop("checked")) {
      buttons.prop("disabled", false);
    } else {
      buttons.prop("disabled", true);
    }
  }

  minnayatteruInquiry() {
    let itemsElement = $(".js--inquiry-area .items")
    let allItems = $(".js--item-inquiry").get()
    let minnayatteruNumber = $('.items').data('minnayatteru-number')
    setInterval(function() {
      let newItems = allItems.slice(-4).map(x => x.outerHTML).join('').replace('<hr>', '')
      itemsElement.html(newItems)
      $(".js--item-inquiry").removeClass("d-none")
      let firstItem = $('.js--item-inquiry').first()
      let firstHrItem = $('.js--item-inquiry hr').first()
      let lastItem = $('.js--item-inquiry').last()
      firstItem.css("display", "none")
      firstHrItem.css("display", "none")
      lastItem.fadeOut('fast', function() {
        allItems.unshift(allItems.splice(minnayatteruNumber - 1, 1)[0]);
        $('.js--item-inquiry:first-child hr').remove();
        firstItem.slideDown(500)
        firstHrItem.slideDown(500)
      });
    }, 2000);
  };

  getQueryParams(url) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })
    return params;
  }

  toggleCollapse() {
    const collapsibleButton = document.querySelector('.collapsible');
    const content = document.querySelector('.inter-group__link');
    const isActive = collapsibleButton.classList.contains('active');
    if (isActive) {
      collapsibleButton.classList.remove('active');
      content.style.maxHeight = "0";
    } else {
      collapsibleButton.classList.add('active');
      content.style.maxHeight = content.scrollHeight + "px";
    }
  }
}
