const TIMELEFT = 30;

export class CountDown {
  constructor(objects) {
    this.objects = objects
    this.downloadTimer = null
  }

  start(timeLeft = TIMELEFT) {
    let self = this
    let resendTimer = this.objects.resendTimerTarget
    let btnChangePhoneNumber = this.objects.btnChangePhoneNumberTarget

    resendTimer.parentNode.hidden = false
    resendTimer.textContent = timeLeft;
    resendTimer.parentNode.parentNode.classList.add('pe-none')
    btnChangePhoneNumber.classList.add('pe-none')

    this.downloadTimer = setInterval(function() {
      timeLeft--;
      if (timeLeft <= 0) {
        clearInterval(self.downloadTimer);
        resendTimer.parentNode.hidden = true
        resendTimer.parentNode.parentNode.classList.remove('pe-none')
        btnChangePhoneNumber.classList.remove('pe-none')
        return
      }
      resendTimer.textContent = timeLeft;
    },1000);
  }

  stop() {
    clearInterval(this.downloadTimer);
  }
}
