import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'email',
    'invalidFeedbackEmail',
    'submitFormGift',
  ]

  createGift(event) {
    let $btnSubmit = $(event.target).closest('.button__gift__page').find('button')
    let mailText = this.emailTarget.value
    let invalidEmailTarget = this.invalidFeedbackEmailTarget
    $btnSubmit.prop('disabled', true)
    let disabledButton = false
    let invalidEmail = false

    if (mailText.length > 0) {
      invalidEmailTarget.style['display'] = 'none'
      invalidEmailTarget.textContent = ''
    } else {
      invalidEmailTarget.style['display'] = 'block'
      invalidEmailTarget.textContent = 'メールアドレスを正しく入力してください'
    }

    let self = this
    if (event.keyCode === 13 || event.type === 'click') {
      Promise.resolve(this.validateEmail(mailText)).then(function(response){
        if(!response) {
          invalidEmail = true
          disabledButton = true
          self.emailTarget.style['border'] = '1px solid #C31731'
        } else {
          $btnSubmit.closest('form').submit()
        }

        $btnSubmit.prop('disabled', disabledButton);
      })
    }
  }

  validateEmail(email) {
    let invalid = this.invalidFeedbackEmailTarget

    let validEmail = email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (validEmail == null) {
      invalid.style['display'] = 'block'
      invalid.textContent = 'メールアドレスを正しく入力してください'
      return false
    }

    invalid.style['display'] = 'none'
    invalid.textContent = ''
    return true
  }

  changeFocus() {
    this.submitFormGiftTarget.removeAttribute('disabled')
    let invalidEmailTarget = this.invalidFeedbackEmailTarget
    invalidEmailTarget.style['display'] = 'none'
    invalidEmailTarget.textContent = ''
  }
}
